<table class="map" *ngIf="world.height">
  <tr *ngFor="let lat of latitude">
    <td
      id="position_{{lat}}_{{long}}"
      *ngFor="let long of longitude"
      [class]="{'rover_is_here': amIonYou(lat, long)}"

    >
      <div *ngIf="amIonYou(lat, long)" class="spacer" [ngStyle]="getOrientation()">{{lat}}_{{long}}</div>
      <div *ngIf="!amIonYou(lat, long)" class="spacer" >{{lat}}_{{long}}</div>
    </td>
  </tr>
</table>

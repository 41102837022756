<div class="content" role="main">
  <header>
    <mat-toolbar color="primary">
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Angular Rover</span>
      <span class="example-spacer"></span>
      <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
        <mat-icon>favorite</mat-icon>
      </button>
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon>share</mat-icon>
      </button>
    </mat-toolbar>
  </header>

  <mat-card class="card-container">
    <mat-card-title>Mars Rover Localization Panel</mat-card-title>
    <mat-card-subtitle style="color: grey">This panel allows you to move the rover throw a map:
    </mat-card-subtitle>
    <mat-card-content>
      <div class="containerX">
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex
             class="coloredContainerX box">
          <div fxFlex class="form">
            <div class="card">
              <form [formGroup]="locationForm" (ngSubmit)="handleClick()">
                <div class="form-group">
                  <div *ngIf="!roverWasLanded">
                    <mat-form-field appearance="legacy" color="black">
                      <mat-label>Eigth</mat-label>
                      <input
                        matInput
                        type="number"
                        placeholder="Eigth"
                        min=0
                        formControlName="height"
                        [disabled]="roverWasLanded"
                      >
                    </mat-form-field>
                    <mat-form-field appearance="legacy" color="black">
                      <mat-label>Width</mat-label>
                      <input
                        matInput
                        type="number"
                        min=0
                        placeholder="Mars square width"
                        formControlName="width"
                        [disabled]="roverWasLanded"
                      >
                    </mat-form-field>
                    <mat-form-field appearance="legacy" color="black">
                      <mat-label>Latitude</mat-label>
                      <input
                        matInput
                        type="number"
                        min="0"
                        placeholder="Latitude"
                        formControlName="latitude"
                        [disabled]="roverWasLanded"
                      >
                      <mat-hint>Nord-South</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" color="black">
                      <mat-label>Longitude</mat-label>
                      <input
                        matInput
                        type="number"
                        min="0"
                        placeholder="Longitude"
                        formControlName="longitude"
                        [disabled]="roverWasLanded"
                      >
                      <mat-hint>East-West</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" color="black">
                      <mat-label>Orientation</mat-label>
                      <mat-select formControlName="orientation">
                        <mat-option
                        *ngFor="let orientation of orientationList"
                        [value]="orientation.value"
                        >{{ orientation.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <mat-form-field appearance="legacy" color="black">
                    <mat-label>Command</mat-label>
                    <input
                      matInput
                      type="text"
                      placeholder="Enter your command: AALAARALA"
                      formControlName="command"
                    >
                    <mat-hint>A: Advance, L: Left, R: Right</mat-hint>
                  </mat-form-field>
                </div>

                <button
                  mat-raised-button
                  color="primary"
                  [disabled]="!locationForm.valid"
                  type="submit"
                  >START</button>
              </form>
            </div>
          </div>
          <div fxFlex class="map">Map :
            <app-map></app-map>
            <app-keyboard></app-keyboard>
          </div>
          <div fxFlex class="sidebar">
            <app-sidebar></app-sidebar>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <pre>{{ actualPosition | json }}</pre>

  <footer>
    <div class="container">
      <div class="row">
        Mars Rover Position Panel
      </div>
    </div>
  </footer>
</div>
